import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import {  NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private spinner: NgxSpinnerService,
              private messages: ToastrService
    ) {

       // redirect to home if already logged in
       if (this.authenticationService.userValue) {
        this.router.navigate(['/admin/a']);
        }
      }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
  });

  // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/admin/a';

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.spinner.show();
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.authenticationService.login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(
            (data) => {

                if (data.statusCode === 200) {
                  this.messages.success(data.message, 'User Login');
                  this.router.navigate([this.returnUrl]);
                }
                if (data.statusCode === 204) {
                  this.messages.info(data.message, 'Credential not match');
                }
                if (data.statusCode === 500) {
                  this.messages.error(data.message, 'Internal Server');
                }

                this.spinner.hide();
            },
            error => {
                this.error = error;
                this.messages.error(error, 'User Login');
            });
}

}
