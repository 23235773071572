import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class MasterService {
    constructor(private http: HttpClient) { }

    getAllRTOS() {
        return this.http.get<any>(`${environment.apiUrl}/RTO/getRTO?RTONoID=0`);
    }

    getRTOById(id: number) {
        return this.http.get<any>(`${environment.apiUrl}/RTO/getRTO?RTONoID=${id}`);
    }

    getAllURP(RtoId: string[], SectorNo: string[]) {
        return this.http.get<any>(`${environment.apiUrl}/URP/getURP?RtoId=${RtoId}&SectorNo=${SectorNo}`);
    }
    getAllURS(RtoId: string[], SectorNo: string[], URPNo: string[], URCNo: string[]) {
        return this.http.get<any>(`${environment.apiUrl}/URS/getURS?RtoId=${RtoId}&SectorNo=${SectorNo}&URPNo=${URPNo}&URCNo=${URCNo}`);
    }
    getAllURC(RtoId: string[], SectorNo: string[], URPNo: string[]) {
        return this.http.get<any>(`${environment.apiUrl}/URC/getURC?RtoId=${RtoId}&SectorNo=${SectorNo}&URPNo=${URPNo}`);
    }
    getAllBlock(RtoId: string[], SectorNo: string[], URPNo: string[], URCNo: string[], URSNo: string[]) {
        return this.http.get<any>(`${environment.apiUrl}/Block/getBlock?RtoId=${RtoId}&SectorNo=${SectorNo}&URPNo=${URPNo}&URCNo=${URCNo}&URSNo=${URSNo}`);
    }
    getAllSector(RtoId: string[]) {
        return this.http.get<any>(`${environment.apiUrl}/Sector/getSector?RtoId=${RtoId}`);
    }
    getAllSUIDs(RtoId: string[], SectorNo: string[], URPNo: string[], URCNo: string[], URSNo: string[], BlockNo: string[]) {
        return this.http.get<any>(`${environment.apiUrl}/SUID/getSUID?RtoId=${RtoId}&SectorNo=${SectorNo}&URPNo=${URPNo}&URCNo=${URCNo}&URSNo=${URSNo}&BlockNo=${BlockNo}`);
    }
    getTypeWiseAttribute(type: any) {
        return this.http.get<any>(`${environment.apiUrl}` + `Type/GetTypeWiseAttribute?type=` + type + `,-`);
    }
    RefreshSuspiciousentry() {
        return this.http.get<any>(`${environment.apiUrl}` + `Type/RefreshSuspiciousentry`);
        
    }
}
