import { Component, OnInit } from '@angular/core';

import { google } from "google-maps";
import { Geojsons } from '../_services/geojsons';

declare var google : google;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
 
  
    
  constructor(private _geojsonservice: Geojsons) { }

  ngOnInit(): void {
   
//    const map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
//       zoom: 18,
//       center: { lat: 19.199748, lng: 72.951638 },
//       mapTypeId: "satellite",
//     });
    
//     this._geojsonservice.getUtilityMISPoints([''],
//     ['1'],
//     ['MIS_POINT', 'UTILITY POINT'],mhmm
//     [''],
//     [''],
//     [''],
//     [''],
//     ['']).subscribe(data => {
//       map.data.addGeoJson(
//         data.data,
       
//         );
      
       
//         const icons: Record<string, { icon: string }> = {
//           MIS_POINT: {
//             icon: "/assets/marker.png",
//           }
         
//         };
//         for (let i = 0; i < data.data.features.length; i++) {
//           const marker = new google.maps.Marker({
//             position:  new google.maps.LatLng(data.data.features[i].properties.latitude,data.data.features[i].properties.longitude),
//             icon: '/assets/marker.png',
//             map: map,
          
//           });
//         }
// });

//   // Create markers.
  
//   const bounds = new google.maps.LatLngBounds(
//     new google.maps.LatLng(19.199748, 72.951638),
//     new google.maps.LatLng(19.204506, 72.954177)
//   );
//   // The photograph is courtesy of the U.S. Geological Survey.
//   let image = "../../assets/v1.png";


  
//   const overlay = new USGSOverlay(bounds, image);
//   overlay.setMap(map);
//   const toggleButton = document.createElement("button");
//   toggleButton.textContent = "Toggle";
//   toggleButton.classList.add("custom-map-control-button");
//   const toggleDOMButton = document.createElement("button");
//   toggleDOMButton.textContent = "Toggle DOM Attachment";
//   toggleDOMButton.classList.add("custom-map-control-button");
//   toggleButton.addEventListener("click", () => {
//     overlay.toggle();
//   });
//   toggleDOMButton.addEventListener("click", () => {
//     overlay.toggleDOM(map);
//   });
//   map.controls[google.maps.ControlPosition.TOP_RIGHT].push(toggleDOMButton);
//   map.controls[google.maps.ControlPosition.TOP_RIGHT].push(toggleButton);
  }
 
  
  clearLayer(): void {
  

    
//     this._geojsonservice.getUtilityMISPoints([''],
//     ['1'],
//     ['DP RESERVATION', 'DP ROAD', 'DP WATER'],
//     [''],
//     [''],
//     [''],
//     [''],
//     ['']).subscribe(data => {
//       map.data.addGeoJson(
//         data.data
//         );
      
// });
  
  
  }
}
/**
   * The custom USGSOverlay object contains the USGS image,
   * the bounds of the image, and a reference to the map.
   */
  
  class USGSOverlay extends google.maps.OverlayView {
    
    bounds: google.maps.LatLngBounds;
    image: string;
    div: HTMLDivElement;
    constructor(bounds: google.maps.LatLngBounds, image: string) {
      super();
      this.bounds = bounds;
      this.image = image;
    }
    /**
     * onAdd is called when the map's panes are ready and the overlay has been
     * added to the map.
     */
    onAdd() {
      this.div = document.createElement("div");
      this.div.style.borderStyle = "none";
      this.div.style.borderWidth = "0px";
      this.div.style.position = "absolute";
      // Create the img element and attach it to the div.
      const img = document.createElement("img");
      img.src = this.image;
      img.style.width = "100%";
      img.style.height = "100%";
      img.style.position = "absolute";
      this.div.appendChild(img);
      // Add the element to the "overlayLayer" pane.
      const panes = this.getPanes();
      panes.overlayLayer.appendChild(this.div);
    }
    draw() {
      // We use the south-west and north-east
      // coordinates of the overlay to peg it to the correct position and size.
      // To do this, we need to retrieve the projection from the overlay.
      const overlayProjection = this.getProjection();
      // Retrieve the south-west and north-east coordinates of this overlay
      // in LatLngs and convert them to pixel coordinates.
      // We'll use these coordinates to resize the div.
      const sw = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getSouthWest()
      );
      const ne = overlayProjection.fromLatLngToDivPixel(
        this.bounds.getNorthEast()
      );

      // Resize the image's div to fit the indicated dimensions.
      if (this.div) {
        this.div.style.left = sw.x + "px";
        this.div.style.top = ne.y + "px";
        this.div.style.width = ne.x - sw.x + "px";
        this.div.style.height = sw.y - ne.y + "px";
      }
    }
    /**
     * The onRemove() method will be called automatically from the API if
     * we ever set the overlay's map property to 'null'.
     */
    // onRemove() {
    //   if (this.div) {
    //     this.div.parentNode.removeChild(this.div);
    //     delete this.div;
    //   }
    // }
    /**
     *  Set the visibility to 'hidden' or 'visible'.
     */
    hide() {
      if (this.div) {
        this.div.style.visibility = "hidden";
      }
    }
    show() {
      if (this.div) {
        this.div.style.visibility = "visible";
      }
    }
    toggle() {
      if (this.div) {
        if (this.div.style.visibility === "hidden") {
          this.show();
        } else {
          this.hide();
        }
      }
    }
    toggleDOM(map: google.maps.Map<Element> | google.maps.StreetViewPanorama | null) {
      if (this.getMap()) {
        this.setMap(null);
      } else {
        this.setMap(map);
      }
    }
  }
