import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './_helpers/auth.guard';
import { Role } from './_Models/role';
import { HomeComponent } from './home/home.component';


const routes: Routes = [{
  path: 'login', component: LoginComponent
},
{ path: 'register', component: RegisterComponent
},
{
path: '',
component: HomeComponent,
canActivate: [AuthGuard]
},
{
path: 'admin',
loadChildren: () =>
        // tslint:disable-next-line: max-line-length
        import('../app/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Authority, Role.Surveyor] }
},
{
path: 'authority',
loadChildren: () =>
        import('../app/authority/authority.module').then(m => m.AuthrityModule), canActivate: [AuthGuard], data: { roles: [Role.Authority] }
},
{
path: 'surveyor',
loadChildren: () =>
        import('../app/surveyor/surveyor.module').then(m => m.SurveyorModule), canActivate: [AuthGuard], data: { roles: [Role.Surveyor] }
},
{ path: '**', redirectTo: '' }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true}), FormsModule, ReactiveFormsModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
