import { Component } from '@angular/core';
import { Role } from './_Models/role';
import { User } from './_Models/User';
import { AuthenticationService } from './_services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CIMSApplication';
  user: User;

  constructor(private authenticationService: AuthenticationService) {
      this.authenticationService.user.subscribe(x => {
        this.user = x;
      });
  }

  get isAdmin() {
      return this.user && this.user.roleName === Role.Admin;
  }

  logout() {
      this.authenticationService.logout();
  }
}
