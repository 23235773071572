import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
// import { SrvRecord } from 'dns';

@Injectable({ providedIn: 'root' })
export class Geojsons {
    constructor(private http: HttpClient) { }

    getUtilityMISPoints(SectorNo: string[],
                                        RTONo: string[],
                                        UIFile: any,
                                        URPNo: string[],
                                        URCNo: string[],
                                        URSNo: string[],
                                        BlockNo: string[],
                                        SUIDNo: string[]) {
        return this.http.get<any>(`${environment.apiUrl}GeoJsons/GetMISUtilityPointGeojsons?SectorNo=${SectorNo}&RTONo=${RTONo}&UIFile=${UIFile}&URPNo=${URPNo}&URCNo=${URCNo}&URSNo=${URSNo}&BlockNo=${BlockNo}&SUIDNo=${SUIDNo}`);
    }

    getDPReservation(SectorNo: string[], RTONo: string[], UIFile: string[]) {
        return this.http.get<any>(`${environment.apiUrl}GeoJsons/GetDPGeoJson?SectorNo=${SectorNo}&RTONo=${RTONo}&UIFile=${UIFile}`);
    }

    getLayers() {
        return this.http.get<any>(`${environment.apiUrl}Type/getLayer`);
    }

    getImages(suid: string) {
        return this.http.get(`${environment.apiUrl}GeoJsons/getImagePath?SUID=${suid}`);
    }
    getStructureSUIDList(rto: string, sector: string, urp: string, structureno: string) {
        return this.http.get(`${environment.apiUrl}/Type/GetStructureSUIDList?RTONo=${rto}&SectorNo=${sector}&URPNo=${urp}&StructureNo=${structureno}`);
    }
    uploadFile(formData:any){
        return this.http
    .post(`${environment.apiUrl}/UploadFile/UploadFile`, formData);
    
    }
    getWalkThroughImage(rto: string, sector: string, urp: string, urc: string,filename:string) {
        return this.http.get(`${environment.apiUrl}/WalkThrough/getWalkThroughImage?rtoNumber=${rto}&sectorNumber=${sector}&urpNumber=${urp}&urcNumber=${urc}&filename=${filename}`);
    }
}
