import { string } from '@amcharts/amcharts4/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterService } from 'src/app/_services/master.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-suspiciousentry',
  templateUrl: './suspiciousentry.component.html',
  styleUrls: ['./suspiciousentry.component.scss']
})
export class SuspiciousentryComponent implements OnInit {
  Urplist: any = [];
  Urclist: any = [];
  Urslist: any = [];
  Blocklist: any = [];
  Suidlist: any = [];
  Rtolist: any = [];
  Sectorlist: any = [];
 
  Urpselected : any;
  Urcselected : any;
  Ursselected : any;
  Blockselected :any;
  Suidselected: any = [];
  public Rtoselected: any;
  public Sectorselected: any;
  
  dtOptions: any;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  areaSelected: any;
  maxCarpet: any;
  min: number;
  max: number;
  _areaList: any[];
  constructor(  private _masterService: MasterService,
    private _spiner: NgxSpinnerService) { }

  ngOnInit(): void {


    this._masterService.getAllRTOS().subscribe(data => {
      this.Rtolist = data.data;
      this._spiner.hide();
    });

    this.showTabledata();
    this.bindSelectBox();

  }
  RTOChange() {

    this._masterService.getAllSector(this.Rtoselected.map((x: { id: any; }) => x.id)).subscribe(data => {
      this.Sectorlist = data.data;
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
     
      dtInstance.columns(1).search('4').draw();
    });
  }
  SectorChange() {
    
    this._masterService.getAllURP(this.Rtoselected.map((x: { id: any; }) => x.id),
    this.Sectorselected.id).subscribe( data => {
      this.Urplist = data.data;
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
     
      dtInstance.columns(2).search(this.Sectorselected.name).draw();
    });
  }
  URPChange() {
    this._masterService.getAllURC(this.Rtoselected.map((x: { id: any; }) => x.id),
    this.Sectorselected.id,
    this.Urpselected.id).subscribe(data => {
      this.Urclist = data.data;
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
   
      dtInstance.columns(3).search(this.Urpselected.name).draw();
    });
  }
  URCChange() {
    this._masterService.getAllURS(this.Rtoselected.map((x: { id: any; }) => x.id),
    this.Sectorselected.id,
    this.Urpselected.id,
    this.Urcselected.id).subscribe(data => {
      this.Urslist = data.data;
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      
      dtInstance.columns(4).search(this.Urcselected.name).draw();
    });
  }
  URSChange() {
    this._masterService.getAllBlock(this.Rtoselected.map((x: { id: any; }) => x.id),
    this.Sectorselected.id,
    this.Urpselected.id,
    this.Urcselected.id,
    this.Ursselected.id).subscribe(data => {
      this.Blocklist = data.data;
    });
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
     
      dtInstance.columns(5).search(this.Ursselected.name).draw();
    });
  }
  BlockChange() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
     
      dtInstance.columns(6).search(this.Blockselected.name).draw();
    });
  }

  showTabledata() {
    $.fn['dataTable'].ext.search.push((_settings: any, data: any, _dataIndex: any) => {
      const id = parseFloat(data[39]) || 0; // use data for the id column
      if ((isNaN(this.min) && isNaN(this.max)) ||
        (isNaN(this.min) && id < this.max) ||
        (this.min < id && isNaN(this.max)) ||
        (this.min < id && id < this.max)) {
        return true;
      }
      return false;
    });
    this.dtOptions = {
      ajax: `${environment.apiUrl}Type/GetSuspiciousentry`,
      pagingType: 'full_numbers',
      scrollX: true,
      columns: [

        { title: 'SUID', data: 'suid' },
        { title: 'RTO No', data: 'rto' },
        { title: 'Sector No', data: 'sector' },
        { title: 'URP No', data: 'urp' },
        { title: 'URC No', data: 'urc' },
        { title: 'URS No', data: 'urs' },
        { title: 'Block No', data: 'block' },
        { title: 'Owner Name', data: 'owneR_NAME' },
        { title: 'Type OF Owner', data: 'typE_OF_OWNER' },
        { title: 'Gender', data: 'gender' },
        { title: 'Age', data: 'age' },
        { title: 'Husband/Spouse Name', data: 'husbanD_SPOUSE_NAME' },
        { title: 'Family Member', data: 'familY_MEMBER' },
        { title: 'Owner Aadhar Card No.', data: 'owneR_AADHAR_CARD' },
        { title: 'Owner PAN Card No.', data: 'owneR_PAN_CARD' },
        { title: 'OwneR Ration Card No.', data: 'owneR_RATION_CARD' },
        { title: 'OwneR Electricity Bill', data: 'owneR_ELECTRICITY_BILL' },
        { title: 'Property Tax No.', data: 'propertY_TAX_NO' },
        { title: 'Tax_Receip No', data: 'taX_RECEIPT_NO' },
        { title: 'Passport No', data: 'passporT_NO' },
        { title: 'Occupent Name', data: 'occupenT_NAME' },
        { title: 'Relationship With OwneR', data: 'relationshiP_WITH_OWNER' },
        { title: 'Type OF Occupent', data: 'typE_OF_OCCUPANT' },
        { title: 'Occupent Adhar Card No', data: 'occupenT_AADHAR_CARD' },
        { title: 'Occupent Pan Card No', data: 'occupenT_PAN_CARD' },
        { title: 'Occupen Voter Card', data: 'occupenT_VOTER_CARD' },
        { title: 'Occupying Structure Since', data: 'occupyinG_STRUCTURE_SINCE' },
        { title: 'Building/Slum Name', data: 'buildinG_SLUM_NAME' },
        { title: 'TOTAL NO OF FLOORS', data: 'totaL_NO_OF_FLOORS' },
        { title: 'Floor No', data: 'flooR_NO' },
        { title: 'Structure No', data: 'structurE_NO' },
        { title: 'Structure Type', data: 'structurE_TYPE' },
        { title: 'Structure Use', data: 'structurE_USE' },
        { title: 'Type Of Commercial Activity', data: 'typE_OF_COMMERCIAL_ACTIVITY' },
        { title: 'Name', data: 'name' },
        { title: 'Name Of Amenity', data: 'namE_OF_AMENITY' },
        { title: 'Name Of Commercial  Activity', data: 'namE_OF_COMMERCIAL_ACTIVITY' },
        { title: 'Possession Area', data: 'carpeT_POSSESSION_AREA_SQM' },
        { title: 'Tolerated Structure', data: 'tolerateD_STRUCTURE' },
        { title: 'Type Of Toleraed Structure', data: 'typE_OF_TOLERATED_STRUCTURE' },
        { title: 'Land Parcel', data: 'lanD_PARCEL' },
        { title: 'Address', data: 'address' },
        { title: 'Remarks', data: 'remarks' },
        { title: 'Structure Status', data: 'status' },
        { title: 'Status Of Structure Or Land Development', data: 'statuS_OF_STRUCTURE_OR_LAND_DEVELOPEMNT' },
        { title: 'Mis Remark', data: 'miS_REMARKS' },
        { title: 'Mis Status', data: 'miS_STATUS' },
        { title: 'Comments', data: 'comments' },
        { title: 'Comments By', data: 'commentS_BY' },
        { title: 'Authority Remark', data: 'authoritY_REMARKS' },
        { title: 'Latitude', data: 'latitude' },
        { title: 'Longitude', data: 'longitude' },
      
        { title: 'Authorised Signatory', data: 'authoriseD_SIGNATORY' },
        { title: 'Other Authorised Signatory', data: 'otheR_AUTHORISED_SIGNATORY' },
        { title: 'Ownership', data: 'ownership' },
        { title: 'Right Assigned', data: 'righT_ASSIGNED' },

        { title: 'Duplicate Item', data: 'duplicateItem' },
      ],
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excelHtml5',

          title: 'Suspicious Entry',
          message: 'Thane City Suspicious Entry Date : ' + new Date().getDate() + '/' + new Date().getMonth() +
          '/' + new Date().getFullYear() + ' \n',
        },
      ]
    };

   
  
  }
  Searching(colIndex: any, value: any): void {
    //// const colIndex = 1;
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns(colIndex).search(value).draw();
    });
}
bindSelectBox()
{
  this._areaList = [];
  this._areaList.push({type: 'Residential', value: 'Residential' });
  this._areaList.push({type: 'Commercial', value: 'Commercial' });
  this._areaList.push({type: 'Industrial', value: 'Industrial' });
  this._areaList.push({ type: 'Religious',value: 'Religious'  });
  this._areaList.push({ type: 'Educational',value: 'Educational'  });
  this._areaList.push({ type: 'Temple',value: 'Temple'  });
  this._areaList.push({ type: 'Land Parcel',value: 'Parcels'  });
}
searchingByType()
{
  this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.columns().search('').draw();
    dtInstance.columns(32).search(this.areaSelected.value).draw();
  
  });
  
  this.Urpselected = '';
  this.Urcselected = '';
  this.Ursselected = '';
  this.Blockselected = '';
  this.Rtoselected = '';
  this.Sectorselected = '';
}
refreshSuspiciousentry()
{
  this._masterService.RefreshSuspiciousentry().subscribe(data => {
    if (data.statusCode === 200) {
      window.location.reload();
    }
   
  });
}

clearFilter() {

  this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.columns().search('').draw();
  });

  this.Urpselected = '';
  this.Urcselected = '';
  this.Ursselected = '';
  this.Blockselected = '';
  this.Rtoselected = '';
  this.Sectorselected = '';
}

}
